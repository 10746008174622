import {preload} 		from './modules/preload.js'
import {nav} 			from './modules/nav.js'
import {counter} 		from './modules/counter.js'
import {slider}			from './modules/slider.js'
import {rellax} 		from './modules/rellax.js'
import {aos} 			from './modules/maos.js'

window.onload = function () {
	preload()
	nav()
	counter()
	slider()
	rellax();
	aos()
}
