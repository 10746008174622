import Rellax from 'rellax/rellax.min'

export function rellax() {
	if (document.querySelector('.rellax')) {
		var rellax = new Rellax('.rellax', {
			center: true,
			speed: -2
		})

		const resizeObserver = new ResizeObserver((entries) => rellax.refresh())
		resizeObserver.observe(document.body)
	}
}
