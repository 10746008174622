import AOS from 'aos';

export function aos() {
	AOS.init({
		disable: 'mobile',
		duration: 800,
		offset: 300,
		delay: 120,
		once: true
	})
}