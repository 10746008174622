import counterUp from 'counterup2'

export function counter() {
    const callback = entries => {
		entries.forEach( entry => {
			const el = entry.target
			if ( entry.isIntersecting && !el.classList.contains('counted')) {
				el.classList.add('counted');
				counterUp( el, {
					duration: 1000,
					delay: 16
				} )
			}
		})
	}

	const IO = new IntersectionObserver( callback, { threshold: 1 } )

	document.querySelectorAll('.count').forEach((el) => {
		if (el) {
			IO.observe(el)
		}
	});
}