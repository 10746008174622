import Splide from '@splidejs/splide'

export function slider() {
	const sliders = []
	var elms = document.getElementsByClassName('js-splide')

	for (var i = 0; i < elms.length; i++) {
		sliders.push(new Splide(elms[i]).mount())
	}

	return sliders
}
