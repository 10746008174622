export function nav() {
	function toggleMenu() {
		var body = document.querySelector('body')
		var nav__button = document.querySelector('.nav__button')

		if (body.classList.contains('js-nav-open')) {
			body.classList.remove('js-nav-open')
			nav__button.classList.remove('is-active')
		} else {
			body.classList.add('js-nav-open')
			nav__button.classList.add('is-active')
		}
	}

	document.querySelectorAll('.js-nav__button').forEach((item) => item.addEventListener('click', toggleMenu))
	document.querySelectorAll('.nav__site li:not(.has-children)').forEach((item) => item.addEventListener('click', toggleMenu))

	// hide header on scroll down ==============================================
	var didScroll
	var lastScrollTop = 0
	var delta = 10

	document.addEventListener('scroll', function () {
		didScroll = true;
		isWindowTop()
	})

	document.querySelector('.site__header').classList.add('nav-down')

	setInterval(function () {
		if (didScroll) {
			if (!document.querySelector('.nav__button').classList.contains('is-active')) {
				hasScrolled()
			}
			didScroll = false
		}
	}, 250)

	// hasScrolled
	function hasScrolled() {
		var st = window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) return

		// If they scrolled down and are past 50vh, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > window.innerHeight / 2) {
			// Scroll Down
			document.querySelector('.site__header').classList.remove('nav-down')
			document.querySelector('.site__header').classList.add('nav-up')
		} else {
			// Scroll Up
			document.querySelector('.site__header').classList.remove('nav-up')
			document.querySelector('.site__header').classList.add('nav-down')
		}

		lastScrollTop = st
	}

	// isWindowsTop
	function isWindowTop() {
		if (window.pageYOffset < 200) {
			document.querySelector('.site__header').classList.add('is-top')
		} else {
			document.querySelector('.site__header').classList.remove('is-top')
		}
	}
}
